import { render, staticRenderFns } from "./LookView.vue?vue&type=template&id=fc089442&scoped=true&"
import script from "./LookView.vue?vue&type=script&lang=js&"
export * from "./LookView.vue?vue&type=script&lang=js&"
import style0 from "./LookView.vue?vue&type=style&index=0&id=fc089442&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc089442",
  null
  
)

export default component.exports