<template>
  <el-dialog
    top="10vh"
    :close-on-click-modal="false"
    class="import-add"
    :visible.sync="dialogVisible"
  >
    <div class="title">考试成绩</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>

    <div class="body">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="viewContent"
        :style="index % 2 === 1 ? ' height: 50px;' : ''"
      >
        <div class="nameText" :style="index > 0 ? 'border-top: 0px;' : ''">
          {{ item.productName }}
        </div>
        <div class="contentText" :style="index > 0 ? 'border-top: 0px;' : ''">
          {{ item.productScore }}
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" @click="dialogVisible = false">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getResult } from '@/api/certCenter'
import to from 'await-to'

export default {
  name: 'LookView',
  data() {
    return {
      dialogVisible: false,
      list: [],
    }
  },
  methods: {
    show(item) {
      this.dialogVisible = true
      console.log(item)
      this.onGetResult(item)
    },
    async onGetResult(item) {
      const [res, err] = await to(getResult({ appointId: item.id }))
      if (err) return this.$message.warning(err.msg)
      // this.certList = res.data
      res.data.unshift({
        productName: '科目',
        productScore: '成绩',
      })
      this.list = res.data
    },
  },
}
</script>
<style lang="scss" scoped>
.import-add {
  ::v-deep.el-dialog {
    position: relative;
    width: 720px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
      // max-height: 90vh;
    }
    .body {
      box-sizing: border-box;
      padding: 50px 50px 25px 50px;
      .viewContent {
        display: flex;
        font-size: 16px;
        height: 70px;
        .nameText {
          flex: 1;
          border-bottom: 1px solid #c9c9c9;
          border-top: 1px solid #c9c9c9;
          border-left: 1px solid #c9c9c9;
          // width: 160px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .contentText {
          border: 1px solid #c9c9c9;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          padding: 20px;
        }
      }
    }
    .title {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .footer {
      padding: 20px 52px 20px;
      text-align: center;
      // border-top: 1px solid #c7c7c7;
    }
  }
}
</style>
