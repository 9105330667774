<template>
  <div>
    <table-list
      class="tableList"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :tabs-list="tabsList"
      :options="{ selection: true, operatesWidth: 220, fixScrollTable: true }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>

    <look-view ref="lookView"></look-view>
  </div>
</template>

<script>
//表格tabs
const tabsList = [
  {
    prop: 'appointStatus',
    activeValue: '',
    children: [
      //状态，0.删除，1编辑中，2已发布
      { value: 0, label: '待提交资料' },
      { value: 1, label: '资料审核中' },
      { value: 5, label: '审核未通过' },
      { value: 3, label: '培训中' },
      { value: 4, label: '已完成' },
    ],
  },
]
//表格标题
const tableTitle = '考证报名列表'
const btns = _this => [
  {
    label: '导入成绩与证书',
    type: 'primary',
    method: _this.handleImport,
  },
  {
    label: '学习统计',
    type: 'primary',
    method: _this.handleCertStudyStatiscs,
  },
  {
    label: '导出报名资料',
    type: 'primary',
    method: _this.handleDataExport,
  },
  {
    label: '导出报名列表',
    type: 'primary',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '报名人姓名',
    prop: 'userName',
  },
  {
    label: '报名人手机号',
    prop: 'mobile',
  },
  {
    label: '证书',
    prop: 'certPrivateId',
    type: 'select',
    children: _this.selectTypeList.map(item => ({
      value: item.certId,
      label: item.certName,
    })),
  },
  {
    label: '创建时间',
    prop: 'examTime',
    type: 'picker',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'userName',
    label: '报名人姓名',
    minWidth: '130px',
  },
  {
    prop: 'mobile',
    label: '手机号码',
    minWidth: '134px',
  },
  {
    prop: 'certName',
    label: '报名证书',
    minWidth: '150px',
  },
  {
    prop: 'certLevelShow',
    label: '报名等级',
    minWidth: '136px',
  },
  {
    prop: 'achievement',
    label: '成绩',
    minWidth: 100,
    align: 'center',
    render: (h, { row }) => {
      return [
        h(
          'el-button',
          {
            props: { type: 'text' },
            on: {
              click: () => {
                _this.onLookView(row)
              },
            },
          },
          row.appointStatus == 4 ? '查看' : '',
        ),
      ]
    },
  },
  {
    prop: 'appointStatusShow',
    label: '当前状态',
    minWidth: '136px',
  },
  {
    prop: 'certPrivateTrainName',
    label: '所在培训计划',
    minWidth: '136px',
  },
  {
    prop: 'createTime',
    label: '报名时间',
    sortable: 'sortable',
    minWidth: '150px',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '报名资料',
    method: _this.onEnrollData,
  },
  {
    label: '学习记录',
    isShow: item => item.appointStatus == 3 || item.appointStatus == 4,
    method: _this.onStudyEecord,
  },
  // {
  //   label: '查看成绩',
  //   isShow: item => item.appointStatus == 4,
  //   method: _this.onViewGrades,
  // },
]
// import total from '../../../assets/address/city'
import LookView from './components/LookView/LookView.vue'
import TableList from '@/components/TableList'
import {
  certDropDownList,
  cerEnrollEndTrain,
  downloadDataExport,
  getCerEnrollList,
} from '@/api/certCenter'
import to from 'await-to'
export default {
  components: {
    TableList,
    LookView,
  },
  data() {
    return {
      tableTitle,
      btns,
      searchForm,
      columns,
      operates,
      tabsList,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      // total,
      queryParams: {},
      select: [],
      list: [],
      selectTypeList: [],
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
    }
  },
  created() {
    this.selectTypeData()
    this.certificateList()
  },
  methods: {
    async certificateList() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(getCerEnrollList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list.map(item => ({
        ...item,
        cityStr: item.provinceName + '-' + item.cityName,
        // gradeListStr: '正在开发中...',
        certLevelShow: item.certLevelShow != null ? item.certLevelShow : '--',
        certPrivateTrainName: item.certPrivateTrainName != null ? item.certPrivateTrainName : '--',
        // gradeListStr: item.gradeList.length
        //   ? item.gradeList.map(item => item.value).join('、')
        //   : '—',
      }))
      this.pager.total = res.data.total
    },

    async selectTypeData() {
      const [res, err] = await to(certDropDownList({}))
      if (err) return this.$message.warning(err.msg)
      // this.certList = res.data
      this.selectTypeList = res.data
    },
    // async selectTypeData() {
    //   const [res, err] = await to(selectType({}))
    //   if (err) return this.$message({ type: 'error', message: err.msg })
    //   this.selectTypeList = res.data
    // },
    // 查询按钮
    onSearch(queryParams) {
      console.log(123123123)
      this.pager.current = 1
      this.queryParams = queryParams
      console.log(this.queryParams)
      if (queryParams.examTime && queryParams.examTime !== 0) {
        this.queryParams.beginTime = queryParams.examTime[0]
        this.queryParams.endTime = queryParams.examTime[1].split(' ')[0] + ' 23:59:59'
      } else {
        this.queryParams.beginTime = ''
        this.queryParams.endTime = ''
      }
      this.certificateList()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    handleImport() {
      console.log('导入')
      this.$router.push({
        path: '/certCenter/enrollImportCert',
        // query: {
        //   appointId: row.id,
        // },
      })
    },
    onLookView(row) {
      console.log(row)
      this.$refs.lookView.show(row)
    },
    handleCertStudyStatiscs() {
      this.$router.push({
        path: '/certCenter/certStudyStatiscs',
      })
    },

    async handleDataExport() {
      let ids = []
      this.select.map(v => ids.push(v.id))
      const { current, size } = this.pager
      const [res, err] = await to(
        downloadDataExport({ current, size, ...this.queryParams, ids: ids }),
      )
      if (err) return this.$message.warning(err.msg)
      console.log(res)
      let blob = new Blob([res], {
        type: 'application/zip',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '报名资料.zip'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async handleExport() {
      let appointIds = []
      this.select.map(v => appointIds.push(v.id))
      const { current, size } = this.pager
      const [res, err] = await to(
        cerEnrollEndTrain({ current, size, ...this.queryParams, appointIds: appointIds }),
      )
      if (err) return this.$message.warning(err.msg)
      console.log(res)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '证书报名管理.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },

    onEnrollData(row) {
      this.$router.push({
        path: '/certCenter/enrollDetail',
        query: {
          appointId: row.id,
        },
      })
    },
    onStudyEecord(row) {
      console.log('222222')
      this.$router.push({
        path: '/certCenter/studydetail',
        query: {
          privateTrainId: row.certPrivateTrainId,
          certRelationTrainId: row.certRelationTrainId,
          userId: row.userId,
        },
      })
    },
    onViewGrades() {
      console.log('33333')
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.certificateList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.certificateList()
    },
  },
}
</script>

<style scoped lang="scss"></style>
